$primary: #1A95D3;
$theme-background: #0055AC;
$theme-background-text: white;
$theme-dark-text: #20252C;
$side-menu-width: 250px;
$border-radius: 12px;
$black: #535256;
$topbar-height: 70px;

@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/_variables";
