@import './../../assets/styles/variables';

.QrCodePage{
  @media(max-width: map-get($grid-breakpoints, "md")){
    padding: 30px 15px 0px 15px;
  }

  .qr-block{
    max-width: 500px;
    height: auto;
    margin: 50px 0 0 35px;

    @media(max-width: map-get($grid-breakpoints, "md")){
      max-width: 100%;
      margin: 0;
    }
  }

  .qr-block__code{
    max-width: 100%;
    display: block;
    height: auto;

    @media(max-width: map-get($grid-breakpoints, "md")){
      margin: auto;
      max-height: calc(100vh - 230px);
    }
  }

  .qr-block__buttons{
    text-align: center;
    margin-top: 1em;

    @media(max-width: map-get($grid-breakpoints, "md")){
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: white;
      padding: 30px;
      border-top-right-radius: 27px;
      border-top-left-radius: 27px;
      box-shadow: 1px 1px 4px rgba(0,0,0,.15);
    }
  }
}