.Paginator{
  &.paginator{
    margin-top: 1em;
  }

  .paginator__row-count{
    font-size: .75em;
    text-align: center;
  }

  .paginator__group{
    justify-content: center;
  }
}

