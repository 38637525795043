@import './../../assets/styles/variables';

.OperationDetailsPage{
  padding: 10px 0;

  .form-button{
    @media(max-width: map-get($grid-breakpoints, "sm")){
      width: 100%;
    }
  }
}