@import './../../assets/styles/variables';

.DiscardOperationModal{
  .discard-operation__feedback{
    margin-top: 30px;
    text-align: center;
  }

  .discard-operation__feedback--success{
    color: $black;
  }

  .discard-operation__feedback--error{
    color: $red;
  }

  .discard-operation__final{
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .discard-operation__final--success{
    color: $black;
  }

  .discard-operation__final--error{
    color: $red;
  }
}