@import '../../assets/styles/variables';

.Simulator{
  .total{
    border-radius: 27px;
    background-color: $primary;
    color: $white;
    padding: 10px;
    text-align: center;
  }

  .total__amount{
    margin: 0;
    font-weight: bolder;
    font-size: 47px;
  }

  .total__description{
    margin: 0;
  }

  .simulator-form__option{
    display: block;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .simulator-form{
    padding: 30px 60px;

    @media(max-width: map-get($grid-breakpoints, "sm")){
      padding: calc(10px + 2vw) 20px;
    }
  }

  .simulator-form__option_value{
    text-align: center;
    color: #535256;
    margin-bottom: 15px;
    font-weight: bolder;
  }

  .simulator-form__option_slider{

  }

  .simulator-form__option_labels{
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    color: #535256;
  }

  .simulator-form__option_label{
    font-weight: bold;
  }
}