@import '../../assets/styles/variables';

.OperationFormDocumentation{
  .scroller{
    @media(max-width: map-get($grid-breakpoints, "sm")) {
      overflow-x: scroll;
      width: 100vw;
      margin: 0 -45px;
    }
  }

  .documents{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media(max-width: map-get($grid-breakpoints, "sm")){
      padding: 0 45px;
    }

    @media(max-width: map-get($grid-breakpoints, "sm")){
      flex-wrap: nowrap;
    }
  }

  .documents__img-button{
    position: relative;
    margin: 10px;
    z-index: 2;
    opacity: 0;
    transition: opacity ease .5s;
    background-color: white;
    border: 1px solid grey;
    border-radius: 50%;
    color: grey;
    width: 60px;
    height: 60px;

    display: flex;
    align-items: center;
  }

  .documents__img-button--add{

  }

  .documents__img-button--remove{

  }

  .documents__img-button--download{

  }

  .documents__img-container{
    padding-right: 1em;
    padding-bottom: 1em;
    min-width: 260px;

    @media(max-width: map-get($grid-breakpoints, "md")){
      min-width: auto;
      flex-basis: 50%;
    }

    @media(max-width: map-get($grid-breakpoints, "sm")){
      flex-basis: auto;
      padding: 0 .5em;
      min-width: 75vw;
    }
  }

  .documents__img{
    width: 100%;
    height: 370px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    border: 1px solid $primary;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }

  .documents__img:hover,
  .documents__img--active{
    &::after{
      content: '';
      position: absolute;
      background-color: rgba(0,0,0,.5);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    .documents__img-button{
      opacity: 1;
    }
  }

  .documents__img--blank{
    border-color: grey;

    .documents__img-button{
      opacity: 1;
    }
  }

  .documents__img--error{
    border-color: #D12525;
  }

  .documents__img-error{
    position: absolute;
    width: 100%;
    left: 0;
    background-color: #D12525;
    padding: 3px;
    text-align: center;
    color: white;
    font-weight: bolder;
    bottom: 0;
    margin: 0;
  }

  .documents__img-loading-bar{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $primary;
    opacity: .5;
    transition: height .25s ease;
  }

  .documents__img-spinner-container{
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: none;
  }

  .documents__img--loading{
    .documents__img-spinner-container{
      display: block;
    }
  }
}