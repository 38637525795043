.NotificationsPage{

  .notification{
    cursor: pointer;
  }

  .notification__header{
    margin: 0 0 .5em 0;
  }

  .notification__description{
    margin: 0;
  }

  .notification--unread{
    background-color: #e9ecef;
  }

  .loading-status{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .loading-status__error{
    text-align: center;
  }
}