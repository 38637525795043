@import '../../assets/styles/variables';

.OperationsPage{
  padding: 10px 0;
}

.operation{
  display: block;
  color: $black;
  font-size: .9em;
  margin-top: 20px;

  &:hover{
    text-decoration: none;
  }
}

.operation__inner{
  border: 1px solid rgba(83, 82, 86, 0.2);
  padding: 15px;
  border-radius: 20px;
}

.operation__line{
  margin: 0;
  line-height: 1em;
  color: #334048;
  margin-bottom: 5px;
}

.operation__line--title{
  color: black;
  margin-bottom: .5em;
  font-weight: bolder;

  font-size: 1em;
  line-height: 1.2em;
}