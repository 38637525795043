@import './../../assets/styles/variables';

.ChangeAvatarModal{
  .change-avatar__feedback{
    margin-top: 30px;
    text-align: center;
  }

  .change-avatar__feedback--success{
    color: $black;
  }

  .change-avatar__feedback--error{
    color: $red;
  }

  .change-avatar__final{
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .change-avatar__final--success{
    color: $black;
  }

  .change-avatar__final--error{
    color: $red;
  }
}