@import './../../assets/styles/variables';

.SimulatorPage{
  .simulator{

  }

  .simulator__body{
    padding: 30px;
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
    box-shadow: 1px 1px 4px rgba(0,0,0,.15);
    background-color: white;
    padding-bottom: 100px;

    @media(max-width: map-get($grid-breakpoints, "md")){
      padding: 20px;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }
  }

  .simulator__header{
    display: block;
    margin: 0 auto 1em auto;
    max-width: 80%;
    height: auto;
    margin-top: 30px;
  }
}